import { addons } from '@storybook/addons'
import { create } from '@storybook/theming'
import pkg from '../package.json'

const title = `${pkg.name} ${pkg.version}`;

const theme = create({
  brandTitle: `<header>
    <img src="/img/ParaWithLogo.png" alt="${title}" style="width: 100px; display: block; margin-inline: auto;" />
    <h1 style="font-size: .8rem; text-align: center;">${title}</h1>
  </header>`,
  brandUrl: "https://beta.paralab.io",
  brandTarget: "_blank",
  base: "light",
})

addons.setConfig({
  theme,
})
